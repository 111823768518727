
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import { searchReferringProviders } from "@/api/referringProvider.api";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import useFilters from "@/modules/common/useFilters";
import debounce from "lodash.debounce";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  emptyListError?: string;
  items: [];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    boolList: { code?: boolean | null; name: string }[];
  };
  showInactive: boolean;
}
export default defineComponent({
  name: "ReferringProviders",
  components: { PaginationUi },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { orderList, putFiltersToUrl, getFiltersFromUrl } = useFilters();
    orderList.value = [
      {
        name: "Name",
        key: "lastName",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Credentials",
        key: "credentials",
        isFilter: true,
        keyword: null,
      },
      {
        name: "NPI",
        key: "npi",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Organization",
        key: "organization",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Address",
        key: "address",
        isFilter: true,
        keyword: null,
      },
      {
        name: "City",
        key: "city",
        isFilter: true,
        keyword: null,
      },
      {
        name: "State",
        key: "state",
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      keyword: "",
      orderBy: "lastName",
      IsAdvancedOrderBy: false,
      isDecr: false,
      showInactive: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
      panel: {},
      dict: {
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
          { code: null, name: "All" },
        ],
      },
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "lastName";
      data.IsAdvancedOrderBy = false;
      data.isDecr = false;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      getFiltersFromUrl();
      organizationId.value = getOrganization();
      setCurrentPageTitle("Referring Providers");
      await getAll();
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      putFiltersToUrl();

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.value.map((item) => {
            return item.key;
          }),
        },
        ShowInactive: data.showInactive,
        GlobalSearch: data.keyword,
      };

      const res = await searchReferringProviders(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      data.emptyListError = undefined;
      if (data.items.length == 0) {
        const currentFilters = orderList.value.filter((f) => {
          return (
            f.keyword != undefined && f.keyword !== "" && f.keyword != null
          );
        });
        if (currentFilters.length > 0) {
          data.emptyListError =
            "**No results match your filter criteria. Please clear all filters to view list";
        } else {
          data.emptyListError = "List is Empty";
        }
      }
    }

    function selectItem(item) {
      const path = encodeURIComponent(route.fullPath);
      router.push({
        name: "referringProviderPage",
        params: { referringProviderId: item.id },
        query: { breadcrumb: path },
      });
    }

    function newReferringProvider() {
      const path = encodeURIComponent(route.fullPath);
      router.push({
        name: "referringProviderPage",
        query: { breadcrumb: path },
      });
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    return {
      organizationId,
      clearSearch,
      data,
      selectItem,
      orderList,
      newReferringProvider,
      debounceSearch,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
    };
  },
});
